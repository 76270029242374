$dark-1: #0e1419;
$dark-2: #29303a;
// $border-dark: rgba($base-color, 0.88);

@font-face {
  font-family: 'FatBlack';
  src: url("./fonts/Fat-Black.ttf") format('opentype');
  font-weight: 300;
}
@font-face {
  font-family: 'FzThuPhapTieuTu';
  src: url("./fonts/FzThuPhapTieuTu-Full.ttf") format('opentype');
  font-weight: 300;
}
@font-face {
  font-family: 'UVFCandlescriptPro';
  src: url("./fonts/UVFCandlescriptPro.ttf") format('opentype');
  font-weight: 300;
}
@font-face {
  font-family: 'FHCordelia-Regular';
  src: url("./fonts/FHCordelia-Regular.ttf") format('opentype');
  font-weight: 300;
}
@font-face {
  font-family: 'THManchesterSignature';
  src: url("./fonts/THManchesterSignature.ttf") format('opentype');
  font-weight: 300;
}

// Hoai Co
@font-face {
  font-family: 'SVNHCAlterner';
  src: url("./fonts/hoaico/SVNHCAlterner.otf") format('opentype');
  font-weight: 300;
}
@font-face {
  font-family: 'SVNHCYeomanGothic';
  src: url("./fonts/hoaico/SVNHCYeomanGothic.otf") format('opentype');
  font-weight: 300;
}
@font-face {
  font-family: 'SVNHCTransformacio';
  src: url("./fonts/hoaico/SVNHCTransformacio.otf") format('opentype');
  font-weight: 300;
}
@font-face {
  font-family: 'SVNHCStrenuous';
  src: url("./fonts/hoaico/SVNHCStrenuous.otf") format('opentype');
  font-weight: 300;
}
@font-face {
  font-family: 'SVNHCOldNewspaperTypes';
  src: url("./fonts/hoaico/SVNHCOldNewspaperTypes.otf") format('opentype');
  font-weight: 300;
}
@font-face {
  font-family: 'SVNHCNewyorkClean';
  src: url("./fonts/hoaico/SVNHCNewyorkClean.otf") format('opentype');
  font-weight: 300;
}
@font-face {
  font-family: 'SVNHCOrganGrinder';
  src: url("./fonts/hoaico/SVNHCOrganGrinder.otf") format('opentype');
  font-weight: 300;
}

// End Hoai co


//UVF
@font-face {
  font-family: 'UTM\ Facebook';
  src: url("./fonts/uvf/UTM\ Facebook.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UTM\ FacebookB K&T';
  src: url("./fonts/uvf/UTM\ FacebookB\ K&T.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UTM\ FacebookK&TBoldItalic';
  src: url("./fonts/uvf/UTM\ FacebookK&TBoldItalic.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UTM\ FacebookR K&T';
  src: url("./fonts/uvf/UTM\ FacebookR\ K&T.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVF\ A Charming Expanded';
  src: url("./fonts/uvf/UVF\ A\ Charming\ Expanded.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVF\ AbrilFatface Regular';
  src: url("./fonts/uvf/UVF\ AbrilFatface\ Regular.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVF\ Aire Roman Std';
  src: url("./fonts/uvf/UVF\ Aire\ Roman\ Std.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVF\ Angelic War';
  src: url("./fonts/uvf/UVF\ Angelic\ War.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVF\ Aphrodite Pro';
  src: url("./fonts/uvf/UVF\ Aphrodite\ Pro.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVF\ AQUUS-Regular';
  src: url("./fonts/uvf/UVF\ AQUUS-Regular.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVF\ Assassin';
  src: url("./fonts/uvf/UVF\ Assassin.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVF\ Barmbrack';
  src: url("./fonts/uvf/UVF\ Barmbrack.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVF\ Bleeding Cowboys';
  src: url("./fonts/uvf/UVF\ Bleeding\ Cowboys.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVF\ Buttermilk Script';
  src: url("./fonts/uvf/UVF\ Buttermilk\ Script.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVF\ Centeria';
  src: url("./fonts/uvf/UVF\ Centeria.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVF\ Champion Script Pro';
  src: url("./fonts/uvf/UVF\ Champion\ Script\ Pro.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVF\ Chikita';
  src: url("./fonts/uvf/UVF\ Chikita.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVF\ Chops Normal';
  src: url("./fonts/uvf/UVF\ Chops\ Normal.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVF\ Cider Script';
  src: url("./fonts/uvf/UVF\ Cider\ Script.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVF\ Daddys Girl';
  src: url("./fonts/uvf/UVF\ Daddys\ Girl.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVF\ Didot LT Std';
  src: url("./fonts/uvf/UVF\ Didot\ LT\ Std.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVF\ FiolexGirlVH';
  src: url("./fonts/uvf/UVF\ FiolexGirlVH.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVF\ Geotica 2012';
  src: url("./fonts/uvf/UVF\ Geotica\ 2012.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVF\ ginga';
  src: url("./fonts/uvf/UVF\ ginga.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVF\ Haymaker';
  src: url("./fonts/uvf/UVF\ Haymaker.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVF\ HeraBig-Black';
  src: url("./fonts/uvf/UVF\ HeraBig-Black.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVF\ Intro Inline';
  src: url("./fonts/uvf/UVF\ Intro\ Inline.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVF\ Jellyka Endless Voyage';
  src: url("./fonts/uvf/UVF\ Jellyka\ Endless\ Voyage.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVF\ Kaileen';
  src: url("./fonts/uvf/UVF\ Kaileen.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVF\ Kingthings_Petrock_vi';
  src: url("./fonts/uvf/UVF\ Kingthings_Petrock_vi.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVF\ Lavanderia Regular';
  src: url("./fonts/uvf/UVF\ Lavanderia\ Regular.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVF\ Lobster12';
  src: url("./fonts/uvf/UVF\ Lobster12.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVF\ Metroscript';
  src: url("./fonts/uvf/UVF\ Metroscript.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVF\ Mishka';
  src: url("./fonts/uvf/UVF\ Mishka.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVF\ MussicaSwash';
  src: url("./fonts/uvf/UVF\ MussicaSwash.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVF\ Narziss Swirls';
  src: url("./fonts/uvf/UVF\ Narziss\ Swirls.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVF\ Narziss-Drops';
  src: url("./fonts/uvf/UVF\ Narziss-Drops.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVF\ Parfait Scrip Stylistic';
  src: url("./fonts/uvf/UVF\ Parfait\ Scrip\ Stylistic.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVF\ Pepita Script';
  src: url("./fonts/uvf/UVF\ Pepita\ Script.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVF\ Perla';
  src: url("./fonts/uvf/UVF\ Perla.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVF\ Pistilli-Roman';
  src: url("./fonts/uvf/UVF\ Pistilli-Roman.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVF\ Rapture Heavenly';
  src: url("./fonts/uvf/UVF\ Rapture\ Heavenly.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVF\ Reina 12 Pro';
  src: url("./fonts/uvf/UVF\ Reina\ 12\ Pro.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVF\ RixLoveFool';
  src: url("./fonts/uvf/UVF\ RixLoveFool.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVF\ SlimTony';
  src: url("./fonts/uvf/UVF\ SlimTony.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVF Sofia Regular';
  src: url("./fonts/uvf/UVF\ Sofia\ Regular.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVF SomeWeatz_with_swashes';
  src: url("./fonts/uvf/UVF\ SomeWeatz_with_swashes.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVF Sreda';
  src: url("./fonts/uvf/UVF\ Sreda.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVF Turbayne';
  src: url("./fonts/uvf/UVF\ Turbayne.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVF WazaLTPro';
  src: url("./fonts/uvf/UVF\ WazaLTPro.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVF Youngblood';
  src: url("./fonts/uvf/UVF\ Youngblood.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'VNF Shelia Regular';
  src: url("./fonts/uvf/VNF\ Shelia\ Regular.ttf") format('opentype');
  font-weight: 300;
  }
//end UVF

//UVN
  @font-face {
  font-family: 'UVNAiCap_B';
  src: url("./fonts/uvn/UVNAiCap_B.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNAiCap_R';
  src: url("./fonts/uvn/UVNAiCap_R.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNAiCapNang';
  src: url("./fonts/uvn/UVNAiCapNang.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNAiCapNhe';
  src: url("./fonts/uvn/UVNAiCapNhe.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNAnhHai_B';
  src: url("./fonts/uvn/UVNAnhHai_B.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNAnhHai_BI';
  src: url("./fonts/uvn/UVNAnhHai_BI.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNAnhHai_I';
  src: url("./fonts/uvn/UVNAnhHai_I.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNAnhHai_R';
  src: url("./fonts/uvn/UVNAnhHai_R.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNAnhHaiNhe_I';
  src: url("./fonts/uvn/UVNAnhHaiNhe_I.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNAnhHaiNhe_R';
  src: url("./fonts/uvn/UVNAnhHaiNhe_R.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNAnhSang';
  src: url("./fonts/uvn/UVNAnhSang.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNBachDang_B';
  src: url("./fonts/uvn/UVNBachDang_B.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNBachDang_BI';
  src: url("./fonts/uvn/UVNBachDang_BI.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNBachDang_I';
  src: url("./fonts/uvn/UVNBachDang_I.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNBachDang_R';
  src: url("./fonts/uvn/UVNBachDang_R.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNBachDangNang_I';
  src: url("./fonts/uvn/UVNBachDangNang_I.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNBachDangNang_R';
  src: url("./fonts/uvn/UVNBachDangNang_R.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNBachTuyet_B';
  src: url("./fonts/uvn/UVNBachTuyet_B.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNBachTuyet_BI';
  src: url("./fonts/uvn/UVNBachTuyet_BI.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNBachTuyet_I';
  src: url("./fonts/uvn/UVNBachTuyet_I.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNBachTuyet_R';
  src: url("./fonts/uvn/UVNBachTuyet_R.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNBachTuyetNang_I';
  src: url("./fonts/uvn/UVNBachTuyetNang_I.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNBachTuyetNang_R';
  src: url("./fonts/uvn/UVNBachTuyetNang_R.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNBaiHoc_B';
  src: url("./fonts/uvn/UVNBaiHoc_B.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNBaiHoc_BI';
  src: url("./fonts/uvn/UVNBaiHoc_BI.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNBaiHoc_I';
  src: url("./fonts/uvn/UVNBaiHoc_I.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNBaiHoc_R';
  src: url("./fonts/uvn/UVNBaiHoc_R.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNBaiSau_B';
  src: url("./fonts/uvn/UVNBaiSau_B.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNBaiSau_N';
  src: url("./fonts/uvn/UVNBaiSau_N.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNBaiSau_Nh';
  src: url("./fonts/uvn/UVNBaiSau_Nh.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNBaiSau_R';
  src: url("./fonts/uvn/UVNBaiSau_R.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNBaLe';
  src: url("./fonts/uvn/UVNBaLe.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNBanhMi';
  src: url("./fonts/uvn/UVNBanhMi.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNBanTay';
  src: url("./fonts/uvn/UVNBanTay.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNBayBuom_B';
  src: url("./fonts/uvn/UVNBayBuom_B.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNBayBuom_N';
  src: url("./fonts/uvn/UVNBayBuom_N.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNBayBuom_R';
  src: url("./fonts/uvn/UVNBayBuom_R.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNBayBuomHep_B';
  src: url("./fonts/uvn/UVNBayBuomHep_B.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNBayBuomHep_N';
  src: url("./fonts/uvn/UVNBayBuomHep_N.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNBayBuomHep_R';
  src: url("./fonts/uvn/UVNBayBuomHep_R.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNBenXuan_B';
  src: url("./fonts/uvn/UVNBenXuan_B.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNBenXuan_R';
  src: url("./fonts/uvn/UVNBenXuan_R.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNBinhDuong';
  src: url("./fonts/uvn/UVNBinhDuong.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNBoQuen';
  src: url("./fonts/uvn/UVNBoQuen.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNBucThu';
  src: url("./fonts/uvn/UVNBucThu.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNBuiDoi';
  src: url("./fonts/uvn/UVNBuiDoi.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNButLong1';
  src: url("./fonts/uvn/UVNButLong1.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNButLong2';
  src: url("./fonts/uvn/UVNButLong2.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNCatBien_B';
  src: url("./fonts/uvn/UVNCatBien_B.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNCatBien_BI';
  src: url("./fonts/uvn/UVNCatBien_BI.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNCatBien_I';
  src: url("./fonts/uvn/UVNCatBien_I.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNCatBien_R';
  src: url("./fonts/uvn/UVNCatBien_R.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNCatBienNhe_I';
  src: url("./fonts/uvn/UVNCatBienNhe_I.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNCatBienNhe_R';
  src: url("./fonts/uvn/UVNCatBienNhe_R.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNChimBien_B';
  src: url("./fonts/uvn/UVNChimBien_B.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNChimBien_R';
  src: url("./fonts/uvn/UVNChimBien_R.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNChimBienNang';
  src: url("./fonts/uvn/UVNChimBienNang.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNChimBienNhe';
  src: url("./fonts/uvn/UVNChimBienNhe.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNChinhLuan_B';
  src: url("./fonts/uvn/UVNChinhLuan_B.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNChinhLuan_BI';
  src: url("./fonts/uvn/UVNChinhLuan_BI.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNChinhLuan_I';
  src: url("./fonts/uvn/UVNChinhLuan_I.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNChinhLuan_R';
  src: url("./fonts/uvn/UVNChinhLuan_R.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNCHUKY';
  src: url("./fonts/uvn/UVNCHUKY.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNCoDien_B';
  src: url("./fonts/uvn/UVNCoDien_B.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNCoDien_R';
  src: url("./fonts/uvn/UVNCoDien_R.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNConThuy';
  src: url("./fonts/uvn/UVNConThuy.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNDaLat_B';
  src: url("./fonts/uvn/UVNDaLat_B.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNDaLat_R';
  src: url("./fonts/uvn/UVNDaLat_R.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNDamCuoi_H';
  src: url("./fonts/uvn/UVNDamCuoi_H.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNDamCuoi_R';
  src: url("./fonts/uvn/UVNDamCuoi_R.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNDinhHon';
  src: url("./fonts/uvn/UVNDinhHon.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNDoiMoi';
  src: url("./fonts/uvn/UVNDoiMoi.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNDungDan';
  src: url("./fonts/uvn/UVNDungDan.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNDzungDakao';
  src: url("./fonts/uvn/UVNDzungDakao.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNGiaDinh_B';
  src: url("./fonts/uvn/UVNGiaDinh_B.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNGiaDinh_BI';
  src: url("./fonts/uvn/UVNGiaDinh_BI.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNGiaDinh_I';
  src: url("./fonts/uvn/UVNGiaDinh_I.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNGiaDinh_R';
  src: url("./fonts/uvn/UVNGiaDinh_R.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNGiaDinhHep_B';
  src: url("./fonts/uvn/UVNGiaDinhHep_B.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNGiaDinhHep_BI';
  src: url("./fonts/uvn/UVNGiaDinhHep_BI.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNGiaDinhHep_I';
  src: url("./fonts/uvn/UVNGiaDinhHep_I.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNGiaDinhHep_R';
  src: url("./fonts/uvn/UVNGiaDinhHep_R.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNGiayTrang_B';
  src: url("./fonts/uvn/UVNGiayTrang_B.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNGiayTrang_R';
  src: url("./fonts/uvn/UVNGiayTrang_R.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNGioMay_B';
  src: url("./fonts/uvn/UVNGioMay_B.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNGioMay_BI';
  src: url("./fonts/uvn/UVNGioMay_BI.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNGioMay_I';
  src: url("./fonts/uvn/UVNGioMay_I.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNGioMay_R';
  src: url("./fonts/uvn/UVNGioMay_R.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNGioMayNhe_I';
  src: url("./fonts/uvn/UVNGioMayNhe_I.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNGioMayNhe_R';
  src: url("./fonts/uvn/UVNGioMayNhe_R.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNGiongSong_B';
  src: url("./fonts/uvn/UVNGiongSong_B.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNGiongSong_BI';
  src: url("./fonts/uvn/UVNGiongSong_BI.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNGiongSong_I';
  src: url("./fonts/uvn/UVNGiongSong_I.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNGiongSong_R';
  src: url("./fonts/uvn/UVNGiongSong_R.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNHaiBaTrung';
  src: url("./fonts/uvn/UVNHaiBaTrung.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNHaiLong';
  src: url("./fonts/uvn/UVNHaiLong.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNHanViet';
  src: url("./fonts/uvn/UVNHanViet.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNHoaDao';
  src: url("./fonts/uvn/UVNHoaDao.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNHOAKY';
  src: url("./fonts/uvn/UVNHOAKY.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNHoaTay1';
  src: url("./fonts/uvn/UVNHoaTay1.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNHongHa_B';
  src: url("./fonts/uvn/UVNHongHa_B.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNHongHa_BI';
  src: url("./fonts/uvn/UVNHongHa_BI.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNHongHa_I';
  src: url("./fonts/uvn/UVNHongHa_I.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNHongHa_R';
  src: url("./fonts/uvn/UVNHongHa_R.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNHongHaHep_B';
  src: url("./fonts/uvn/UVNHongHaHep_B.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNHongHaHep_BI';
  src: url("./fonts/uvn/UVNHongHaHep_BI.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNHongHaHep_I';
  src: url("./fonts/uvn/UVNHongHaHep_I.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNHongHaHep_R';
  src: url("./fonts/uvn/UVNHongHaHep_R.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNHuongQue_B';
  src: url("./fonts/uvn/UVNHuongQue_B.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNHuongQue_N';
  src: url("./fonts/uvn/UVNHuongQue_N.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNHuongQue_R';
  src: url("./fonts/uvn/UVNHuongQue_R.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNKeChuyen1';
  src: url("./fonts/uvn/UVNKeChuyen1.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNKeChuyen2';
  src: url("./fonts/uvn/UVNKeChuyen2.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNKeChuyen3';
  src: url("./fonts/uvn/UVNKeChuyen3.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNKieu_B';
  src: url("./fonts/uvn/UVNKieu_B.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNKieu_R';
  src: url("./fonts/uvn/UVNKieu_R.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNKyThuat';
  src: url("./fonts/uvn/UVNKyThuat.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNLacLongQuan_B';
  src: url("./fonts/uvn/UVNLacLongQuan_B.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNLacLongQuan_R';
  src: url("./fonts/uvn/UVNLacLongQuan_R.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNLaXanh_B';
  src: url("./fonts/uvn/UVNLaXanh_B.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNLaXanh_BI';
  src: url("./fonts/uvn/UVNLaXanh_BI.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNLaXanh_I';
  src: url("./fonts/uvn/UVNLaXanh_I.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNLaXanh_R';
  src: url("./fonts/uvn/UVNLaXanh_R.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNLYDO_';
  src: url("./fonts/uvn/UVNLYDO_.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNMangCau_B';
  src: url("./fonts/uvn/UVNMangCau_B.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNMangCau_BI';
  src: url("./fonts/uvn/UVNMangCau_BI.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNMangCau_I';
  src: url("./fonts/uvn/UVNMangCau_I.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNMangCau_R';
  src: url("./fonts/uvn/UVNMangCau_R.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNMangCauNang_I';
  src: url("./fonts/uvn/UVNMangCauNang_I.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNMangCauNang_R';
  src: url("./fonts/uvn/UVNMangCauNang_R.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNMangTre';
  src: url("./fonts/uvn/UVNMangTre.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNMauTim1';
  src: url("./fonts/uvn/UVNMauTim1.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNMauTim2';
  src: url("./fonts/uvn/UVNMauTim2.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNMayChuP_B';
  src: url("./fonts/uvn/UVNMayChuP_B.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNMayChuP_BI';
  src: url("./fonts/uvn/UVNMayChuP_BI.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNMayChuP_I';
  src: url("./fonts/uvn/UVNMayChuP_I.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNMayChuP_R';
  src: url("./fonts/uvn/UVNMayChuP_R.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNMinhMap';
  src: url("./fonts/uvn/UVNMinhMap.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNMoiHong';
  src: url("./fonts/uvn/UVNMoiHong.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNMotMoi';
  src: url("./fonts/uvn/UVNMotMoi.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNMuaThu';
  src: url("./fonts/uvn/UVNMuaThu.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNMucCham';
  src: url("./fonts/uvn/UVNMucCham.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNNguyenDu';
  src: url("./fonts/uvn/UVNNguyenDu.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNNhan_B';
  src: url("./fonts/uvn/UVNNhan_B.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNNhan_BI';
  src: url("./fonts/uvn/UVNNhan_BI.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNNhan_I';
  src: url("./fonts/uvn/UVNNhan_I.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNNhan_R';
  src: url("./fonts/uvn/UVNNhan_R.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNNhanNang_I';
  src: url("./fonts/uvn/UVNNhanNang_I.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNNhanNang_R';
  src: url("./fonts/uvn/UVNNhanNang_R.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNNhatKy_B';
  src: url("./fonts/uvn/UVNNhatKy_B.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNNhatKy_BI';
  src: url("./fonts/uvn/UVNNhatKy_BI.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNNhatKy_I';
  src: url("./fonts/uvn/UVNNhatKy_I.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNNhatKy_R';
  src: url("./fonts/uvn/UVNNhatKy_R.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNPhuongTay';
  src: url("./fonts/uvn/UVNPhuongTay.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNSachVo_B';
  src: url("./fonts/uvn/UVNSachVo_B.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNSachVo_BI';
  src: url("./fonts/uvn/UVNSachVo_BI.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNSachVo_I';
  src: url("./fonts/uvn/UVNSachVo_I.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNSachVo_R';
  src: url("./fonts/uvn/UVNSachVo_R.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNSaigon_B';
  src: url("./fonts/uvn/UVNSaigon_B.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNSaigon_BI';
  src: url("./fonts/uvn/UVNSaigon_BI.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNSaigon_I';
  src: url("./fonts/uvn/UVNSaigon_I.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNSaigon_R';
  src: url("./fonts/uvn/UVNSaigon_R.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNSangSong_B';
  src: url("./fonts/uvn/UVNSangSong_B.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNSangSong_N';
  src: url("./fonts/uvn/UVNSangSong_N.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNSangSong_R';
  src: url("./fonts/uvn/UVNSangSong_R.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNThangGieng_I';
  src: url("./fonts/uvn/UVNThangGieng_I.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNThangGieng_R';
  src: url("./fonts/uvn/UVNThangGieng_R.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNThangVu';
  src: url("./fonts/uvn/UVNThangVu.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNThanhBinh';
  src: url("./fonts/uvn/UVNThanhBinh.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNThanhPho_B';
  src: url("./fonts/uvn/UVNThanhPho_B.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNThanhPho_R';
  src: url("./fonts/uvn/UVNThanhPho_R.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNThanhPhoNang';
  src: url("./fonts/uvn/UVNThanhPhoNang.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNThayGiao_B';
  src: url("./fonts/uvn/UVNThayGiao_B.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNThayGiao_BI';
  src: url("./fonts/uvn/UVNThayGiao_BI.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNThayGiao_I';
  src: url("./fonts/uvn/UVNThayGiao_I.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNThayGiao_R';
  src: url("./fonts/uvn/UVNThayGiao_R.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNThayGiaoNang_I';
  src: url("./fonts/uvn/UVNThayGiaoNang_I.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNThayGiaoNang_R';
  src: url("./fonts/uvn/UVNThayGiaoNang_R.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNThayGiaoNhe_I';
  src: url("./fonts/uvn/UVNThayGiaoNhe_I.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNThayGiaoNhe_R';
  src: url("./fonts/uvn/UVNThayGiaoNhe_R.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNThoiDai1';
  src: url("./fonts/uvn/UVNThoiDai1.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNThoiDai2';
  src: url("./fonts/uvn/UVNThoiDai2.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNThoiNay_B';
  src: url("./fonts/uvn/UVNThoiNay_B.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNThoiNay_BI';
  src: url("./fonts/uvn/UVNThoiNay_BI.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNThoiNay_I';
  src: url("./fonts/uvn/UVNThoiNay_I.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNThoiNay_R';
  src: url("./fonts/uvn/UVNThoiNay_R.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNThoiNayNang_I';
  src: url("./fonts/uvn/UVNThoiNayNang_I.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNThoiNayNang_R';
  src: url("./fonts/uvn/UVNThoiNayNang_R.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNThuTu';
  src: url("./fonts/uvn/UVNThuTu.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNTinTuc_B';
  src: url("./fonts/uvn/UVNTinTuc_B.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNTinTuc_BI';
  src: url("./fonts/uvn/UVNTinTuc_BI.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNTinTuc_I';
  src: url("./fonts/uvn/UVNTinTuc_I.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNTinTuc_R';
  src: url("./fonts/uvn/UVNTinTuc_R.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNTinTucHep_B';
  src: url("./fonts/uvn/UVNTinTucHep_B.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNTinTucHep_BI';
  src: url("./fonts/uvn/UVNTinTucHep_BI.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNTinTucHep_I';
  src: url("./fonts/uvn/UVNTinTucHep_I.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNTinTucHep_R';
  src: url("./fonts/uvn/UVNTinTucHep_R.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNTinTucHepThem_B';
  src: url("./fonts/uvn/UVNTinTucHepThem_B.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNTinTucHepThem_R';
  src: url("./fonts/uvn/UVNTinTucHepThem_R.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNTinTucNhe_I';
  src: url("./fonts/uvn/UVNTinTucNhe_I.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNTinTucNhe_R';
  src: url("./fonts/uvn/UVNTinTucNhe_R.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNVan_B';
  src: url("./fonts/uvn/UVNVan_B.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNVan_R';
  src: url("./fonts/uvn/UVNVan_R.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNVanChuong_B';
  src: url("./fonts/uvn/UVNVanChuong_B.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNVanChuong_I';
  src: url("./fonts/uvn/UVNVanChuong_I.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNVanChuong_R';
  src: url("./fonts/uvn/UVNVanChuong_R.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNVanChuongNang_R';
  src: url("./fonts/uvn/UVNVanChuongNang_R.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNVienDu';
  src: url("./fonts/uvn/UVNVienDu.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNVietSach_B';
  src: url("./fonts/uvn/UVNVietSach_B.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNVietSach_BI';
  src: url("./fonts/uvn/UVNVietSach_BI.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNVietSach_I';
  src: url("./fonts/uvn/UVNVietSach_I.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNVietSach_R';
  src: url("./fonts/uvn/UVNVietSach_R.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNViVi';
  src: url("./fonts/uvn/UVNViVi.TTF") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'UVNVungTau';
  src: url("./fonts/uvn/UVNVungTau.TTF") format('opentype');
  font-weight: 300;
  }
//End UVN

//SVN

@font-face {
  font-family: 'SVN-A Love Of Thunder';
  src: url("./fonts/svn/SVN-A Love Of Thunder.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Aaron Script';
  src: url("./fonts/svn/SVN-Aaron Script.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Abril Fatface';
  src: url("./fonts/svn/SVN-Abril Fatface.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Acherus Italic';
  src: url("./fonts/svn/SVN-Acherus Italic.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Acherus Regular';
  src: url("./fonts/svn/SVN-Acherus Regular.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Adam Gorry';
  src: url("./fonts/svn/SVN-Adam Gorry.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Agency FB Bold';
  src: url("./fonts/svn/SVN-Agency FB Bold.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Agency FB';
  src: url("./fonts/svn/SVN-Agency FB.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Aguda Black';
  src: url("./fonts/svn/SVN-Aguda Black.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Aguda Bold';
  src: url("./fonts/svn/SVN-Aguda Bold.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Aguda Light';
  src: url("./fonts/svn/SVN-Aguda Light.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Aguda Regular';
  src: url("./fonts/svn/SVN-Aguda Regular.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Alek Bold';
  src: url("./fonts/svn/SVN-Alek Bold.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Alek Ornaments';
  src: url("./fonts/svn/SVN-Alek Ornaments.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Alek Regular';
  src: url("./fonts/svn/SVN-Alek Regular.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Aleo Bold Italic';
  src: url("./fonts/svn/SVN-Aleo Bold Italic.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Aleo Bold';
  src: url("./fonts/svn/SVN-Aleo Bold.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Aleo Italic';
  src: url("./fonts/svn/SVN-Aleo Italic.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Aleo Light Italic';
  src: url("./fonts/svn/SVN-Aleo Light Italic.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Aleo Light';
  src: url("./fonts/svn/SVN-Aleo Light.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Aleo Regular';
  src: url("./fonts/svn/SVN-Aleo Regular.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Aller Regular';
  src: url("./fonts/svn/SVN-Aller Regular.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Amethyst Dingbats';
  src: url("./fonts/svn/SVN-Amethyst Dingbats.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Amethyst TrueType';
  src: url("./fonts/svn/SVN-Amethyst TrueType.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Amethyst';
  src: url("./fonts/svn/SVN-Amethyst.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Amperzand';
  src: url("./fonts/svn/SVN-Amperzand.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Amsi Cond Italic';
  src: url("./fonts/svn/SVN-Amsi Cond Italic.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Amsi Cond Regular';
  src: url("./fonts/svn/SVN-Amsi Cond Regular.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Amsi Narw Bold';
  src: url("./fonts/svn/SVN-Amsi Narw Bold.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Amsi Narw Light';
  src: url("./fonts/svn/SVN-Amsi Narw Light.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Anastasia';
  src: url("./fonts/svn/SVN-Anastasia.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Androgyne';
  src: url("./fonts/svn/SVN-Androgyne.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Angellife';
  src: url("./fonts/svn/SVN-Angellife.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Angely Extras';
  src: url("./fonts/svn/SVN-Angely Extras.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Angely Summer';
  src: url("./fonts/svn/SVN-Angely Summer.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Angely';
  src: url("./fonts/svn/SVN-Angely.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Anthem';
  src: url("./fonts/svn/SVN-Anthem.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Apple';
  src: url("./fonts/svn/SVN-Apple.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Appleberry';
  src: url("./fonts/svn/SVN-Appleberry.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Aptima bold italic';
  src: url("./fonts/svn/SVN-Aptima bold italic.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Aptima bold';
  src: url("./fonts/svn/SVN-Aptima bold.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Aptima italic';
  src: url("./fonts/svn/SVN-Aptima italic.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Aptima';
  src: url("./fonts/svn/SVN-Aptima.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Arch Regular';
  src: url("./fonts/svn/SVN-Arch Regular.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Archaic 1897';
  src: url("./fonts/svn/SVN-Archaic 1897.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Arial 2 bold italic';
  src: url("./fonts/svn/SVN-Arial 2 bold italic.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Arial 2 bold';
  src: url("./fonts/svn/SVN-Arial 2 bold.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Arial 2 italic';
  src: url("./fonts/svn/SVN-Arial 2 italic.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Arial 2';
  src: url("./fonts/svn/SVN-Arial 2.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Arial 3 bold italic';
  src: url("./fonts/svn/SVN-Arial 3 bold italic.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Arial 3 bold';
  src: url("./fonts/svn/SVN-Arial 3 bold.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Arial 3 itlaic';
  src: url("./fonts/svn/SVN-Arial 3 itlaic.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Arial 3';
  src: url("./fonts/svn/SVN-Arial 3.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Arial Bold Italic';
  src: url("./fonts/svn/SVN-Arial Bold Italic.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Arial Bold';
  src: url("./fonts/svn/SVN-Arial Bold.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Arial Italic';
  src: url("./fonts/svn/SVN-Arial Italic.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Arial Regular';
  src: url("./fonts/svn/SVN-Arial Regular.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Aslang Barry';
  src: url("./fonts/svn/SVN-Aslang Barry.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Astronout';
  src: url("./fonts/svn/SVN-Astronout.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Athena';
  src: url("./fonts/svn/SVN-Athena.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Avaline Script Catchwords';
  src: url("./fonts/svn/SVN-Avaline Script Catchwords.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Avaline Script Ornaments';
  src: url("./fonts/svn/SVN-Avaline Script Ornaments.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Avaline Script';
  src: url("./fonts/svn/SVN-Avaline Script.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Avengeance';
  src: url("./fonts/svn/SVN-Avengeance.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Avo bold italic';
  src: url("./fonts/svn/SVN-Avo bold italic.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Avo bold';
  src: url("./fonts/svn/SVN-Avo bold.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Avo italic';
  src: url("./fonts/svn/SVN-Avo italic.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Avo';
  src: url("./fonts/svn/SVN-Avo.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-AZ Cupcakes';
  src: url("./fonts/svn/SVN-AZ Cupcakes.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Bali Script';
  src: url("./fonts/svn/SVN-Bali Script.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Balladeer Light';
  src: url("./fonts/svn/SVN-Balladeer Light.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Ballarea Typeface';
  src: url("./fonts/svn/SVN-Ballarea Typeface.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Banana Chip Muffins';
  src: url("./fonts/svn/SVN-Banana Chip Muffins.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Bango';
  src: url("./fonts/svn/SVN-Bango.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Bariol Italic';
  src: url("./fonts/svn/SVN-Bariol Italic.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Bariol';
  src: url("./fonts/svn/SVN-Bariol.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Batman Forever Alternate';
  src: url("./fonts/svn/SVN-Batman Forever Alternate.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Bear';
  src: url("./fonts/svn/SVN-Bear.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Beast';
  src: url("./fonts/svn/SVN-Beast.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Beauty Atok Script';
  src: url("./fonts/svn/SVN-Beauty Atok Script.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Bellico';
  src: url("./fonts/svn/SVN-Bellico.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Bellwethers';
  src: url("./fonts/svn/SVN-Bellwethers.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Beloved';
  src: url("./fonts/svn/SVN-Beloved.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Berkshire Swash';
  src: url("./fonts/svn/SVN-Berkshire Swash.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Billo';
  src: url("./fonts/svn/SVN-Billo.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Bio Sans Regular';
  src: url("./fonts/svn/SVN-Bio Sans Regular.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Bira';
  src: url("./fonts/svn/SVN-Bira.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Birth Of A Hero';
  src: url("./fonts/svn/SVN-Birth Of A Hero.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Bistro Script';
  src: url("./fonts/svn/SVN-Bistro Script.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Blade runner';
  src: url("./fonts/svn/SVN-Blade runner.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Blenda Script';
  src: url("./fonts/svn/SVN-Blenda Script.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Block';
  src: url("./fonts/svn/SVN-Block.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Blog Script';
  src: url("./fonts/svn/SVN-Blog Script.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Blue';
  src: url("./fonts/svn/SVN-Blue.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Book Antiqua bold italic';
  src: url("./fonts/svn/SVN-Book Antiqua bold italic.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Book Antiqua bold';
  src: url("./fonts/svn/SVN-Book Antiqua bold.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Book Antiqua italic';
  src: url("./fonts/svn/SVN-Book Antiqua italic.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Book Antiqua';
  src: url("./fonts/svn/SVN-Book Antiqua.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Bradley Hand';
  src: url("./fonts/svn/SVN-Bradley Hand.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Braley Hand';
  src: url("./fonts/svn/SVN-Braley Hand.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Bree Regular';
  src: url("./fonts/svn/SVN-Bree Regular.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Brother 1816 Italic';
  src: url("./fonts/svn/SVN-Brother 1816 Italic.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Brother 1816 Regular';
  src: url("./fonts/svn/SVN-Brother 1816 Regular.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Brunette';
  src: url("./fonts/svn/SVN-Brunette.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Bruselo Script Ornaments';
  src: url("./fonts/svn/SVN-Bruselo Script Ornaments.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Bruselo Script';
  src: url("./fonts/svn/SVN-Bruselo Script.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Bulgary';
  src: url("./fonts/svn/SVN-Bulgary.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Cafe Lounge 19';
  src: url("./fonts/svn/SVN-Cafe Lounge 19.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Calama';
  src: url("./fonts/svn/SVN-Calama.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Calling Code Bold Italic';
  src: url("./fonts/svn/SVN-Calling Code Bold Italic.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Calling Code Bold';
  src: url("./fonts/svn/SVN-Calling Code Bold.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Calling Code Italic';
  src: url("./fonts/svn/SVN-Calling Code Italic.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Calling Code Regular';
  src: url("./fonts/svn/SVN-Calling Code Regular.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Candy Script';
  src: url("./fonts/svn/SVN-Candy Script.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Caprica Script';
  src: url("./fonts/svn/SVN-Caprica Script.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Carington';
  src: url("./fonts/svn/SVN-Carington.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Cattleya Script';
  src: url("./fonts/svn/SVN-Cattleya Script.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Channel';
  src: url("./fonts/svn/SVN-Channel.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Cherryla';
  src: url("./fonts/svn/SVN-Cherryla.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Ciao Bella Flowers';
  src: url("./fonts/svn/SVN-Ciao Bella Flowers.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Ciao Bella Laurels';
  src: url("./fonts/svn/SVN-Ciao Bella Laurels.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Ciao Bella Leaves';
  src: url("./fonts/svn/SVN-Ciao Bella Leaves.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Ciao Bella Stems';
  src: url("./fonts/svn/SVN-Ciao Bella Stems.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Ciao Bella';
  src: url("./fonts/svn/SVN-Ciao Bella.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Cinnamoncake';
  src: url("./fonts/svn/SVN-Cinnamoncake.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Cintra';
  src: url("./fonts/svn/SVN-Cintra.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Clementine';
  src: url("./fonts/svn/SVN-Clementine.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Coco FY';
  src: url("./fonts/svn/SVN-Coco FY.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Comic Sans MS';
  src: url("./fonts/svn/SVN-Comic Sans MS.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Cookie';
  src: url("./fonts/svn/SVN-Cookie.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Cookies';
  src: url("./fonts/svn/SVN-Cookies.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Corki Regular';
  src: url("./fonts/svn/SVN-Corki Regular.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Corki Rounded';
  src: url("./fonts/svn/SVN-Corki Rounded.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Corki Tuscan Rounded';
  src: url("./fonts/svn/SVN-Corki Tuscan Rounded.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Corki Tuscan';
  src: url("./fonts/svn/SVN-Corki Tuscan.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-D Sari Regular';
  src: url("./fonts/svn/SVN-D Sari Regular.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Daisy';
  src: url("./fonts/svn/SVN-Daisy.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Dancing script';
  src: url("./fonts/svn/SVN-Dancing script.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Darleston';
  src: url("./fonts/svn/SVN-Darleston.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Days';
  src: url("./fonts/svn/SVN-Days.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Deadhead Script';
  src: url("./fonts/svn/SVN-Deadhead Script.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Desire';
  src: url("./fonts/svn/SVN-Desire.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Dessert Menu Script';
  src: url("./fonts/svn/SVN-Dessert Menu Script.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-DK Clochard';
  src: url("./fonts/svn/SVN-DK Clochard.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Dumpling';
  src: url("./fonts/svn/SVN-Dumpling.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Effra Italic';
  src: url("./fonts/svn/SVN-Effra Italic.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Effra';
  src: url("./fonts/svn/SVN-Effra.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Egregio Script';
  src: url("./fonts/svn/SVN-Egregio Script.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Elle NovaC Extra Light';
  src: url("./fonts/svn/SVN-Elle NovaC Extra Light.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Endless Sorrow Catchword';
  src: url("./fonts/svn/SVN-Endless Sorrow Catchword.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Endless Sorrow';
  src: url("./fonts/svn/SVN-Endless Sorrow.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Every Movie Every Night';
  src: url("./fonts/svn/SVN-Every Movie Every Night.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Fadilla';
  src: url("./fonts/svn/SVN-Fadilla.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-FFF Tusj';
  src: url("./fonts/svn/SVN-FFF Tusj.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Fiolex Girls';
  src: url("./fonts/svn/SVN-Fiolex Girls.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Fjalla One';
  src: url("./fonts/svn/SVN-Fjalla One.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Flamingo Script';
  src: url("./fonts/svn/SVN-Flamingo Script.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Fourth Bold';
  src: url("./fonts/svn/SVN-Fourth Bold.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Fourth Extras';
  src: url("./fonts/svn/SVN-Fourth Extras.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Fourth Regular';
  src: url("./fonts/svn/SVN-Fourth Regular.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Franko';
  src: url("./fonts/svn/SVN-Franko.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Freestyle';
  src: url("./fonts/svn/SVN-Freestyle.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Genica Pro';
  src: url("./fonts/svn/SVN-Genica Pro.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Georgia Bold Italic';
  src: url("./fonts/svn/SVN-Georgia Bold Italic.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Georgia Bold';
  src: url("./fonts/svn/SVN-Georgia Bold.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Georgia Italic';
  src: url("./fonts/svn/SVN-Georgia Italic.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Georgia Regular';
  src: url("./fonts/svn/SVN-Georgia Regular.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Gidry';
  src: url("./fonts/svn/SVN-Gidry.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Good Dog';
  src: url("./fonts/svn/SVN-Good Dog.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Gotham Rounded Regular';
  src: url("./fonts/svn/SVN-Gotham Rounded Regular.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Gretoon';
  src: url("./fonts/svn/SVN-Gretoon.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Grocery Rounded';
  src: url("./fonts/svn/SVN-Grocery Rounded.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Hand Gothic Bold';
  src: url("./fonts/svn/SVN-Hand Gothic Bold.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Hand Gothic Regular';
  src: url("./fonts/svn/SVN-Hand Gothic Regular.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Haptic Script';
  src: url("./fonts/svn/SVN-Haptic Script.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Harabaras';
  src: url("./fonts/svn/SVN-Harabaras.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Harman Script';
  src: url("./fonts/svn/SVN-Harman Script.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Heathergreen';
  src: url("./fonts/svn/SVN-Heathergreen.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Helga';
  src: url("./fonts/svn/SVN-Helga.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Helves bold italic';
  src: url("./fonts/svn/SVN-Helves bold italic.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Helves bold';
  src: url("./fonts/svn/SVN-Helves bold.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Helves italic';
  src: url("./fonts/svn/SVN-Helves italic.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Helves';
  src: url("./fonts/svn/SVN-Helves.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Helvetica Neue Bold Italic';
  src: url("./fonts/svn/SVN-Helvetica Neue Bold Italic.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Helvetica Neue Bold';
  src: url("./fonts/svn/SVN-Helvetica Neue Bold.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Helvetica Neue Heavy';
  src: url("./fonts/svn/SVN-Helvetica Neue Heavy.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Helvetica Neue Italic';
  src: url("./fonts/svn/SVN-Helvetica Neue Italic.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Helvetica Neue Light';
  src: url("./fonts/svn/SVN-Helvetica Neue Light.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Helvetica Neue Regular';
  src: url("./fonts/svn/SVN-Helvetica Neue Regular.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Hemi Head';
  src: url("./fonts/svn/SVN-Hemi Head.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Heroe Pro Fleurons';
  src: url("./fonts/svn/SVN-Heroe Pro Fleurons.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Heroe Pro Inline';
  src: url("./fonts/svn/SVN-Heroe Pro Inline.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Heroe Pro Monoline';
  src: url("./fonts/svn/SVN-Heroe Pro Monoline.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Heroe Pro';
  src: url("./fonts/svn/SVN-Heroe Pro.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Hole Hearted';
  src: url("./fonts/svn/SVN-Hole Hearted.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Holidays';
  src: url("./fonts/svn/SVN-Holidays.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Hollie Script Pro';
  src: url("./fonts/svn/SVN-Hollie Script Pro.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Hollycakes';
  src: url("./fonts/svn/SVN-Hollycakes.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Indie';
  src: url("./fonts/svn/SVN-Indie.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Internation';
  src: url("./fonts/svn/SVN-Internation.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Justice League';
  src: url("./fonts/svn/SVN-Justice League.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Kelson Sans Bold';
  src: url("./fonts/svn/SVN-Kelson Sans Bold.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Kelson Sans Light';
  src: url("./fonts/svn/SVN-Kelson Sans Light.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Kelson Sans Regular';
  src: url("./fonts/svn/SVN-Kelson Sans Regular.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-KG Ten Thousand Reasons';
  src: url("./fonts/svn/SVN-KG Ten Thousand Reasons.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Kimberley';
  src: url("./fonts/svn/SVN-Kimberley.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Kitten';
  src: url("./fonts/svn/SVN-Kitten.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Larch Shaded';
  src: url("./fonts/svn/SVN-Larch Shaded.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Linux Libertine bold italic';
  src: url("./fonts/svn/SVN-Linux Libertine bold italic.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Linux Libertine bold';
  src: url("./fonts/svn/SVN-Linux Libertine bold.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Linux Libertine Italic';
  src: url("./fonts/svn/SVN-Linux Libertine Italic.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Linux Libertine regular';
  src: url("./fonts/svn/SVN-Linux Libertine regular.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Lobster';
  src: url("./fonts/svn/SVN-Lobster.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Madre Script';
  src: url("./fonts/svn/SVN-Madre Script.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Manus';
  src: url("./fonts/svn/SVN-Manus.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Maphylla';
  src: url("./fonts/svn/SVN-Maphylla.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Marpesia Pro';
  src: url("./fonts/svn/SVN-Marpesia Pro.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Marujo Fino';
  src: url("./fonts/svn/SVN-Marujo Fino.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Marujo Pictures';
  src: url("./fonts/svn/SVN-Marujo Pictures.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Marujo';
  src: url("./fonts/svn/SVN-Marujo.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Mercury Script Bold';
  src: url("./fonts/svn/SVN-Mercury Script Bold.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Mercury Script Light';
  src: url("./fonts/svn/SVN-Mercury Script Light.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Mercury Script Ornaments Bold';
  src: url("./fonts/svn/SVN-Mercury Script Ornaments Bold.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Mercury Script Ornaments Light';
  src: url("./fonts/svn/SVN-Mercury Script Ornaments Light.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Mercury Script Ornaments Regular';
  src: url("./fonts/svn/SVN-Mercury Script Ornaments Regular.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Mercury Script Regular';
  src: url("./fonts/svn/SVN-Mercury Script Regular.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Merge Bold';
  src: url("./fonts/svn/SVN-Merge Bold.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Merge Regular';
  src: url("./fonts/svn/SVN-Merge Regular.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Mikado Regular';
  src: url("./fonts/svn/SVN-Mikado Regular.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Miller Banner Italic';
  src: url("./fonts/svn/SVN-Miller Banner Italic.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Miller Banner';
  src: url("./fonts/svn/SVN-Miller Banner.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Monday Ornaments';
  src: url("./fonts/svn/SVN-Monday Ornaments.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Monday';
  src: url("./fonts/svn/SVN-Monday.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Moon Star';
  src: url("./fonts/svn/SVN-Moon Star.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-MORVA Ornaments';
  src: url("./fonts/svn/SVN-MORVA Ornaments.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-MORVA';
  src: url("./fonts/svn/SVN-MORVA.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Motion Picture';
  src: url("./fonts/svn/SVN-Motion Picture.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Mutlu';
  src: url("./fonts/svn/SVN-Mutlu.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Neogrey';
  src: url("./fonts/svn/SVN-Neogrey.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Neusa Bold';
  src: url("./fonts/svn/SVN-Neusa Bold.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Neusa';
  src: url("./fonts/svn/SVN-Neusa.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Neutraface 2';
  src: url("./fonts/svn/SVN-Neutraface 2.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-New Athletic M54';
  src: url("./fonts/svn/SVN-New Athletic M54.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Newton';
  src: url("./fonts/svn/SVN-Newton.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Nexa Bold';
  src: url("./fonts/svn/SVN-Nexa Bold.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Nexa Light';
  src: url("./fonts/svn/SVN-Nexa Light.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Nexa Rush Handmade Extended';
  src: url("./fonts/svn/SVN-Nexa Rush Handmade Extended.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Nexa Rush Sans Black';
  src: url("./fonts/svn/SVN-Nexa Rush Sans Black.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Nexa Rush Script';
  src: url("./fonts/svn/SVN-Nexa Rush Script.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Nexa Rush Slab Black Shadow';
  src: url("./fonts/svn/SVN-Nexa Rush Slab Black Shadow.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Nexa Rust Extras Free';
  src: url("./fonts/svn/SVN-Nexa Rust Extras Free.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Nexa Rust Sans Black';
  src: url("./fonts/svn/SVN-Nexa Rust Sans Black.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Nexa Rust Script';
  src: url("./fonts/svn/SVN-Nexa Rust Script.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Nexa Rust Slab Black Shadow';
  src: url("./fonts/svn/SVN-Nexa Rust Slab Black Shadow.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Nickainley Script';
  src: url("./fonts/svn/SVN-Nickainley Script.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Nickel';
  src: url("./fonts/svn/SVN-Nickel.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Nothing';
  src: url("./fonts/svn/SVN-Nothing.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Novelia';
  src: url("./fonts/svn/SVN-Novelia.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Olivier';
  src: url("./fonts/svn/SVN-Olivier.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Oscine';
  src: url("./fonts/svn/SVN-Oscine.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-OscineBold';
  src: url("./fonts/svn/SVN-OscineBold.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Paris Pro';
  src: url("./fonts/svn/SVN-Paris Pro.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Passion';
  src: url("./fonts/svn/SVN-Passion.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-PF Din Text Pro Compressed Regular';
  src: url("./fonts/svn/SVN-PF Din Text Pro Compressed Regular.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-PF Din Text Pro Condensed Regular';
  src: url("./fonts/svn/SVN-PF Din Text Pro Condensed Regular.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-PF Din Text Pro Regular';
  src: url("./fonts/svn/SVN-PF Din Text Pro Regular.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Pokys';
  src: url("./fonts/svn/SVN-Pokys.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Posterizer KG Inline';
  src: url("./fonts/svn/SVN-Posterizer KG Inline.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Prima';
  src: url("./fonts/svn/SVN-Prima.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Product Sans Bold';
  src: url("./fonts/svn/SVN-Product Sans Bold.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Product Sans Regular';
  src: url("./fonts/svn/SVN-Product Sans Regular.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Pyes Pa Headline';
  src: url("./fonts/svn/SVN-Pyes Pa Headline.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Ready';
  src: url("./fonts/svn/SVN-Ready.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Redressed';
  src: url("./fonts/svn/SVN-Redressed.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Reklame Script';
  src: url("./fonts/svn/SVN-Reklame Script.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Reliant';
  src: url("./fonts/svn/SVN-Reliant.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Revolution';
  src: url("./fonts/svn/SVN-Revolution.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Rex';
  src: url("./fonts/svn/SVN-Rex.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-RidemyBike Pro Bold';
  src: url("./fonts/svn/SVN-RidemyBike Pro Bold.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-RidemyBike Pro Dingbat';
  src: url("./fonts/svn/SVN-RidemyBike Pro Dingbat.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-RidemyBike Pro Regular';
  src: url("./fonts/svn/SVN-RidemyBike Pro Regular.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Riesling';
  src: url("./fonts/svn/SVN-Riesling.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Ringdena';
  src: url("./fonts/svn/SVN-Ringdena.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Rio 2016';
  src: url("./fonts/svn/SVN-Rio 2016.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Rocker';
  src: url("./fonts/svn/SVN-Rocker.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Rosalinda';
  src: url("./fonts/svn/SVN-Rosalinda.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Roselina Script';
  src: url("./fonts/svn/SVN-Roselina Script.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Rounded';
  src: url("./fonts/svn/SVN-Rounded.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Russell';
  src: url("./fonts/svn/SVN-Russell.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-SAF';
  src: url("./fonts/svn/SVN-SAF.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Safina Script';
  src: url("./fonts/svn/SVN-Safina Script.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Saldina';
  src: url("./fonts/svn/SVN-Saldina.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Sansation';
  src: url("./fonts/svn/SVN-Sansation.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Santis';
  src: url("./fonts/svn/SVN-Santis.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Sari Regular';
  src: url("./fonts/svn/SVN-Sari Regular.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Sarifa';
  src: url("./fonts/svn/SVN-Sarifa.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Segoe Print';
  src: url("./fonts/svn/SVN-Segoe Print.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Segoe Script';
  src: url("./fonts/svn/SVN-Segoe Script.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Segoe UI Bold Italic';
  src: url("./fonts/svn/SVN-Segoe UI Bold Italic.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Segoe UI Bold';
  src: url("./fonts/svn/SVN-Segoe UI Bold.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Segoe UI Condensed bold';
  src: url("./fonts/svn/SVN-Segoe UI Condensed bold.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Segoe UI Condensed';
  src: url("./fonts/svn/SVN-Segoe UI Condensed.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Segoe UI Italic';
  src: url("./fonts/svn/SVN-Segoe UI Italic.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Segoe UI Light Italic';
  src: url("./fonts/svn/SVN-Segoe UI Light Italic.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Segoe UI Light';
  src: url("./fonts/svn/SVN-Segoe UI Light.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Segoe UI';
  src: url("./fonts/svn/SVN-Segoe UI.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Servetica Medium';
  src: url("./fonts/svn/SVN-Servetica Medium.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Servetica Thin';
  src: url("./fonts/svn/SVN-Servetica Thin.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Sevillana';
  src: url("./fonts/svn/SVN-Sevillana.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Shintia Script';
  src: url("./fonts/svn/SVN-Shintia Script.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Showcase Ornaments';
  src: url("./fonts/svn/SVN-Showcase Ornaments.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Showcase Sans';
  src: url("./fonts/svn/SVN-Showcase Sans.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Showcase Script';
  src: url("./fonts/svn/SVN-Showcase Script.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Showcase Slab';
  src: url("./fonts/svn/SVN-Showcase Slab.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Sign Painter House Script';
  src: url("./fonts/svn/SVN-Sign Painter House Script.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Skill';
  src: url("./fonts/svn/SVN-Skill.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Sliced';
  src: url("./fonts/svn/SVN-Sliced.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Snail';
  src: url("./fonts/svn/SVN-Snail.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Snell Roundhand Script Bold';
  src: url("./fonts/svn/SVN-Snell Roundhand Script Bold.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Snell Roundhand Script';
  src: url("./fonts/svn/SVN-Snell Roundhand Script.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Sofia';
  src: url("./fonts/svn/SVN-Sofia.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Square';
  src: url("./fonts/svn/SVN-Square.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Standly';
  src: url("./fonts/svn/SVN-Standly.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Steady';
  src: url("./fonts/svn/SVN-Steady.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Stella';
  src: url("./fonts/svn/SVN-Stella.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Strangelove';
  src: url("./fonts/svn/SVN-Strangelove.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Student';
  src: url("./fonts/svn/SVN-Student.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Suargie';
  src: url("./fonts/svn/SVN-Suargie.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Sunday';
  src: url("./fonts/svn/SVN-Sunday.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-SVN-A Love Of Thunder';
  src: url("./fonts/svn/SVN-SVN-A Love Of Thunder.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Swashington';
  src: url("./fonts/svn/SVN-Swashington.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Taiga';
  src: url("./fonts/svn/SVN-Taiga.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Tamarindo';
  src: url("./fonts/svn/SVN-Tamarindo.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Teaters';
  src: url("./fonts/svn/SVN-Teaters.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-The Carpenter Bold';
  src: url("./fonts/svn/SVN-The Carpenter Bold.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-The Carpenter Ornaments';
  src: url("./fonts/svn/SVN-The Carpenter Ornaments.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-The Carpenter Patterns';
  src: url("./fonts/svn/SVN-The Carpenter Patterns.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-The Carpenter Pictograms';
  src: url("./fonts/svn/SVN-The Carpenter Pictograms.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-The Carpenter Regular';
  src: url("./fonts/svn/SVN-The Carpenter Regular.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-The Voice Heavy Italic';
  src: url("./fonts/svn/SVN-The Voice Heavy Italic.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-The Voice Heavy Italic';
  src: url("./fonts/svn/SVN-The Voice Heavy Italic.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-The Voice Light';
  src: url("./fonts/svn/SVN-The Voice Light.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-The Voice Light';
  src: url("./fonts/svn/SVN-The Voice Light.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-The Voice Regular';
  src: url("./fonts/svn/SVN-The Voice Regular.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-The Voice Regular';
  src: url("./fonts/svn/SVN-The Voice Regular.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Times New Roman 2 bold italic';
  src: url("./fonts/svn/SVN-Times New Roman 2 bold italic.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Times New Roman 2 bold';
  src: url("./fonts/svn/SVN-Times New Roman 2 bold.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Times New Roman 2 italic';
  src: url("./fonts/svn/SVN-Times New Roman 2 italic.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Times New Roman 2';
  src: url("./fonts/svn/SVN-Times New Roman 2.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Times New Roman Bold Italic';
  src: url("./fonts/svn/SVN-Times New Roman Bold Italic.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Times New Roman Bold';
  src: url("./fonts/svn/SVN-Times New Roman Bold.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Times New Roman Italic';
  src: url("./fonts/svn/SVN-Times New Roman Italic.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Times New Roman';
  src: url("./fonts/svn/SVN-Times New Roman.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Titillium bold';
  src: url("./fonts/svn/SVN-Titillium bold.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Titillium light';
  src: url("./fonts/svn/SVN-Titillium light.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Titillium medium';
  src: url("./fonts/svn/SVN-Titillium medium.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-TradeMark';
  src: url("./fonts/svn/SVN-TradeMark.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Transformer';
  src: url("./fonts/svn/SVN-Transformer.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Trebuchets';
  src: url("./fonts/svn/SVN-Trebuchets.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Trench';
  src: url("./fonts/svn/SVN-Trench.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Tungsten Book';
  src: url("./fonts/svn/SVN-Tungsten Book.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Unthrift';
  src: url("./fonts/svn/SVN-Unthrift.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Valentina';
  src: url("./fonts/svn/SVN-Valentina.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Vanessas Valentine';
  src: url("./fonts/svn/SVN-Vanessas Valentine.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Vanilla Daisy Pro';
  src: url("./fonts/svn/SVN-Vanilla Daisy Pro.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Vantom';
  src: url("./fonts/svn/SVN-Vantom.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Velvetberries';
  src: url("./fonts/svn/SVN-Velvetberries.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Very Berry';
  src: url("./fonts/svn/SVN-Very Berry.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Victoria';
  src: url("./fonts/svn/SVN-Victoria.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Wallington';
  src: url("./fonts/svn/SVN-Wallington.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Wallows Ornaments';
  src: url("./fonts/svn/SVN-Wallows Ornaments.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Wallows';
  src: url("./fonts/svn/SVN-Wallows.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Wednesday';
  src: url("./fonts/svn/SVN-Wednesday.otf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Whimsy';
  src: url("./fonts/svn/SVN-Whimsy.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Yahoo';
  src: url("./fonts/svn/SVN-Yahoo.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Zailla Script';
  src: url("./fonts/svn/SVN-Zailla Script.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Zebra';
  src: url("./fonts/svn/SVN-Zebra.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Zero';
  src: url("./fonts/svn/SVN-Zero.ttf") format('opentype');
  font-weight: 300;
  }
  @font-face {
  font-family: 'SVN-Ziclets';
  src: url("./fonts/svn/SVN-Ziclets.ttf") format('opentype');
  font-weight: 300;
  }
//End SVN